export const getEnvironment = () => {
  if (process.env.VUE_APP_ENVIRONMENT !== undefined) {
    return process.env.VUE_APP_ENVIRONMENT;
  }
  const isDev = ['localhost', 'dev.frontend-rework.bpd2.dmdr.io'].includes(window.location.hostname);
  return isDev ? 'dev' : 'production';
};

export const getApiBaseUrl = () => {
  if (process.env.VUE_APP_API_URL !== undefined) {
    return process.env.VUE_APP_API_URL;
  }

  if (window.location.hostname === 'dev.frontend-rework.bpd2.dmdr.io') {
    return 'https://dev.cms-rework.bpd2.dmdr.io';
  }

  return 'https://cms-rework.bpd2.dmdr.io';
};

export const getEnableConfigurationSave = () => {
  return process.env.VUE_APP_ENABLE_CONFIGURATION_SAVE !== 'false';
};
